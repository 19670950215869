import { useSelector } from "react-redux";
import { SHOP_AUTO_PAY_CUSTOMER_ORDERS } from "constants/featureFlags";

export function useFeatureFlags() {
  const flags = useSelector((state) => state.profile.shop.global_flags);

  const showCustomerOrderAutomaticPayments = () =>
    flags[SHOP_AUTO_PAY_CUSTOMER_ORDERS];

  return { showCustomerOrderAutomaticPayments };
}
