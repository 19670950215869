import React, { useState } from "react";
import styles from "./Popper.module.scss"; // Import your SCSS file

function Popover({ children, text }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.popoverContainer}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>

      {anchorEl && (
        <div
          className={styles.popoverContent}
          style={{ top: anchorEl.offsetTop - 42 }} // Adjust to ensure it's above the children
        >
          <div className={styles.popoverArrow} />

          <div className={styles.popoverBody}>
            <div className={styles.popoverArrowCover} />
            {text}
          </div>
        </div>
      )}
    </div>
  );
}

export default Popover;
