import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import PublishedDiscontinuedProductNotification from "components/Notifications/PublishedDiscontinuedProductNotification/PublishedDiscontinuedProductNotification";

import MenuIcon from "@mui/icons-material/Menu";
import HeaderCartIcon from "components/Icons/HeaderCartIcon/HeaderCartIcon";
import FreeTrialTag from "components/Labels/FreeTrialTag";
import Text from "components/Text";
import { CART_TYPE_BRAND_PLATE } from "constants/constants";
import { useAnalytics, useProfile } from "hooks";
import { useSelector } from "react-redux";
import styles from "./AppBar.module.scss";

const drawerWidth = 220;
const AppBarMui = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function AppBar({ open, handleDrawerOpen }) {
  const { isOnTrial } = useProfile();

  const analytics = useAnalytics();
  const cart = useSelector((state) => state.cart);

  return (
    <AppBarMui
      position="fixed"
      className={styles.appBar}
      open={open}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Text
          color="dark-gray"
          fontSize={12}
          className="text--georgia text--bold hidden-mobile"
        >
          Latest from the blog: &nbsp;
        </Text>
        <a
          className={styles.textBlogLink}
          target="_blank"
          href="https://blankabrand.com/blogs/beyond-the-brand-beauty-blog"
          rel="noreferrer"
          onClick={() =>
            analytics.sendEvent(analytics.LINK_CLICKED_LATEST_FROM_THE_BLOG, {})
          }
        >
          Beyond The Brand Blog
        </a>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          className={styles.center}
          style={{ display: "relative" }}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {isOnTrial() && <FreeTrialTag />}

          <div className={styles["mr-16"]} />
          {cart.type !== CART_TYPE_BRAND_PLATE && (
            <Link
              to="/cart"
              className={styles.cartIconWrapper}
              data-cy="cart-icon"
            >
              <HeaderCartIcon hasItems={cart?.items?.length > 0} />
              <span>
                {cart?.items?.length > 0 && <>({cart?.items?.length})</>}
              </span>
            </Link>
          )}
          <div className={styles.accountLinks}>
            <Link className={`${styles.textLink} text--nunito`} to="/profile">
              My Account
            </Link>
            <Link
              className={`${styles.textLink} text--nunito`}
              to="/logout?no_persist=true"
            >
              Logout
            </Link>
          </div>
        </Box>
      </Toolbar>
    </AppBarMui>
  );
}

AppBar.propTypes = {
  handleDrawerOpen: PropTypes.func,
};

AppBar.defaultProps = {
  handleDrawerOpen: () => {},
};

export default AppBar;
