import Container from "@mui/material/Container";
import { getUser, QUERY } from "api";
import LoadingScreen from "components/LoadingScreen";
import Sidebar from "components/Sidebar";
import {
  useAnalytics,
  useCart,
  usePageViews,
  useProfile,
  useQueryParam,
} from "hooks";
import Cookies from "js-cookie";
import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  matchRoutes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import OnboardingView from "views/OnboardingView";

import { SMALL } from "constants/breakpoints.js";
import {
  SUBSCRIPTION_STATUS_FAILED,
  SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED,
  SUBSCRIPTION_STATUS_UNPAID,
} from "constants/constants";

import queryClient from "api/queryClient";
import DunningModal from "components/Modals/DunningModal";
import PaymentUpdateResponseModal from "components/Modals/PaymentUpdateResponseModal/PaymentUpdateResponseModal";
import { GenericErrorNotification } from "components/Notifications";
import CloseButton from "components/Notifications/CloseButton";
import SuspenseLoading from "components/SuspenseLoading";
import { useMediaQuery } from "react-responsive";
import ErrorView from "views/ErrorView";

import HeaderBar from "components/HeaderBar";
import NewChangePlanModal from "components/Modals/ChangePlanModal/NewChangePlanModal";
import SidebarMobile from "components/SidebarMobile";
import useChangePlanModal from "hooks/useChangePlanModal";
import moment from "moment-timezone";
import { routes } from "routes";
import { createShopSettingsThunk } from "state/shopSettignsSlice";
import MyLogoView from "views/MyLogoView";
import {
  getShopSettingsThunk,
  selectShopSettings,
} from "../../state/shopSettignsSlice";

import "react-toastify/dist/ReactToastify.css";
import styles from "./Main.module.scss";

export default function Main({ redirectPath = "/login" }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParam();
  const cartHook = useCart();
  const profileHook = useProfile();
  const analytics = useAnalytics();
  // do not remove, needed for tracking page views
  usePageViews(useSelector((state) => state.profile.profile));

  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);
  const shop = useSelector((state) => state.profile.shop);

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [updatePaymentModalOpen, setUpdatePaymentModalOpen] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: SMALL });
  const { isLoading } = useQuery(QUERY.getUser, getUser, {
    enabled: !!shop,
  });

  const { onOpenModal } = useChangePlanModal();
  const shopSettings = useSelector(selectShopSettings);

  const isFreeTrial = useMemo(() => {
    if (shop) {
      return shop.trial_days_remaining !== 0;
    }

    return false;
  }, [shop]);

  useEffect(() => {
    toast.clearWaitingQueue();
    // get any active carts
    cartHook.getActiveCartFromServer();
  }, []);

  useEffect(() => {
    // Get all shop settings
    if (!shop.id) {
      return;
    }
    dispatch(getShopSettingsThunk());
  }, [shop]);

  // check if user has come from an affiliate link also
  // check if cookie is already set, could be from marketing site
  useEffect(() => {
    // if cookie already set use original value
    if (Cookies.get("irclickid")) {
      return;
    }
    if (queryParams.get("irclickid")) {
      Cookies.set("irclickid", queryParams.get("irclickid"));
    }
  }, []);

  useEffect(() => {
    if (
      shop.id &&
      shop.subscription_status === SUBSCRIPTION_STATUS_UNPAID &&
      queryParams.get("setup_intent") &&
      queryParams.get("dunning") &&
      shop.onboarding_complete
    ) {
      setUpdatePaymentModalOpen(true);
    }

    // if shop is expired status they need to redo plan selection
    if (
      shop.id &&
      shop.subscription_status === SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED
    ) {
      onOpenModal({
        title: "Your subscription has expired",
        subtitle: "Please select a plan to continue",
      });
    }
  }, [shop]);

  useEffect(() => {
    if (profileHook.isInDunning())
      if (location.pathname !== "/profile/billing/update") {
        toast.clearWaitingQueue();

        if (!updatePaymentModalOpen) {
          toast.error(
            <GenericErrorNotification text="Please update your billing informaiton to continue" />
          );
        }
        analytics.sendEvent(analytics.DUNNING_ATTEMPT_TO_LEAVE, {});
        navigate("/profile/billing/update");
      }
  }, [location, navigate, shop]);

  useEffect(() => {
    if (shopSettings.errorMessage !== "") {
      return;
    }
    if (
      !shopSettings.loading &&
      !shopSettings.error &&
      !shopSettings.values?.timezone
    ) {
      // The user does not have any timezone set up yet.
      // We will guess one and create it
      const timezone = moment.tz.guess();

      if (timezone.length > 0) {
        dispatch(createShopSettingsThunk({ timezone }));
      }
    }
  }, [shopSettings]);

  useEffect(() => {
    if (!isLoggedIn && isLoading) {
      const url = new URL(`${window.location}`);
      const params = new URLSearchParams(url.search);
      params.set("redirect_url", location.pathname);
      navigate({
        pathname: redirectPath,
        search: params.toString(),
      });
    }
  }, [isLoggedIn, isLoading]);

  if (isLoading || !shop.id) {
    return <LoadingScreen />;
  }

  // show onbaording if not complete
  if (!isLoading && !shop.onboarding_complete && shop.id) {
    return <OnboardingView />;
  }

  const getLocationName = () => {
    const route = matchRoutes(routes, location)[0];
    return route.route.name ? route.route.name : "App";
  };

  const handleToast = () => {
    if (!isMobile && shop.count_of_discontinued_products > 0) {
      return "40px";
    }

    // if (isMobile && shop.count_of_discontinued_products > 0) {
    //   return "145px";
    // }

    return 0;
  };

  return (
    <div>
      <div className={styles.mainWrapper}>
        <Helmet>
          <title>{getLocationName()} - Blanka</title>
          <meta
            name="description"
            content="Official app login page app for Shopify, Wix, WooCommerce and more"
          />
        </Helmet>

        {shop.count_of_discontinued_products > 0 && <HeaderBar />}

        {isMobile ? (
          <SidebarMobile
            onOpenDrawer={(open) => setDrawerOpen(open)}
            hasHeaderBar={shop.count_of_discontinued_products > 0}
          />
        ) : (
          <Sidebar
            onOpenDrawer={(open) => setDrawerOpen(open)}
            hasHeaderBar={shop.count_of_discontinued_products > 0}
          />
        )}
        <main
          style={isMobile && !isFreeTrial ? { marginTop: 80 } : {}}
          className={drawerOpen ? styles.main : styles.mainClosed}
        >
          <Container maxWidth={`${isMobile ? "sm" : "xl"}`}>
            <ErrorBoundary
              FallbackComponent={ErrorView}
              onReset={() => {
                window.location.reload();
              }}
            >
              <React.Suspense fallback={<SuspenseLoading />}>
                <Outlet />
              </React.Suspense>

              <PaymentUpdateResponseModal
                open={updatePaymentModalOpen}
                handleClose={() => setUpdatePaymentModalOpen(false)}
              />
              <DunningModal
                open={
                  (shop.subscription_status === SUBSCRIPTION_STATUS_FAILED ||
                    shop.subscription_status === SUBSCRIPTION_STATUS_UNPAID) &&
                  !updatePaymentModalOpen &&
                  !Cookies.get("bl-dunning")
                }
                allowClose={false}
                handleClose={() => {
                  queryClient.refetchQueries([QUERY.getUser]);
                  setUpdatePaymentModalOpen(false);
                }}
              />

              <MyLogoView />
            </ErrorBoundary>
          </Container>
        </main>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        preventDuplicates
        newestOnTop={false}
        limit={1}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        closeButton={CloseButton}
        style={{
          top: handleToast(),
          zIndex: isMobile && 999999999,
        }}
      />
      <NewChangePlanModal />
    </div>
  );
}

Main.propTypes = {};

Main.defaultProps = {};
