import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function Text({
  children,
  variant,
  color,
  fontSize,
  className,
  ...props
}) {
  return (
    <Typography
      variant={variant}
      fontSize={`${fontSize || 12}px`}
      data-testid="Text"
      className={`text--${color} ${className}`}
      {...props}
    >
      {children}
    </Typography>
  );
}

Text.propTypes = {
  variant: PropTypes.oneOf([
    "body1",
    "body2",
    "button",
    "caption",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
  ]),

  color: PropTypes.string,
  className: PropTypes.string,
};

Text.defaultProps = {
  variant: "body1",
  color: "",

  className: "",
};
