import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Text from "components/Text";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ORDER_STATUS_PAYMENT_REQUIRED } from "constants/constants";

import styles from "./Dropdown.module.scss";

function Dropdown({ options, onChange, ...otherProps }) {
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setValue(option);
    if (onChange) {
      onChange(option);
    }
    setIsOpen(false); // Close dropdown after selection
  };

  const mapOptionValue = (option) => {
    if (option === "") return "";
    if (option === ORDER_STATUS_PAYMENT_REQUIRED) return "PAYMENT REQ.";
    return option;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${styles.dropdownWrapper} ${otherProps.className || ""}`}
      ref={wrapperRef}
      {...otherProps}
    >
      <div className={styles.selectContainer} onClick={toggleDropdown}>
        <div className={styles.selectFormControl}>
          {mapOptionValue(value)}

          {value === "" && (
            <Text className={`${styles.optionItem}`} color="gray">
              Select
            </Text>
          )}

          <KeyboardArrowDownIcon
            className={`${styles.dropdownIcon} ${isOpen ? styles.rotate : ""}`}
            fontSize="small"
          />
        </div>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            {options.map((option) => (
              <div
                key={option}
                onClick={() => handleOptionClick(option)}
                className={`${styles.optionItem} ${
                  value === option ? styles.selected : ""
                }`}
                aria-placeholder="Select an option"
              >
                {mapOptionValue(option)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
