/**
 * keep all the queries in constants
 */

const QUERY = {
  getUser: "get-user",
  getProduct: "get-product",
  getProducts: "get-products",
  getDiscounts: "get-discounts",
  getMyProducts: "get-my-products",
  getCustomerOrders: "get-customer-orders",
  getInventoryOrders: "get-inventory-orders",
  getSampleOrders: "get-sample-orders",
  getMyLogoGenerated: "get-my-logo-generated",
  getProfileDataPointsMetadata: "get-profile-data-points-metadata",
};

export default QUERY;
