import { SHOP_ONBOARDING_SHOPIFY } from "constants/constants";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import ShopifyOnboardingStepOne from "views/ShopifyOnboardingView/ShopifyOnboardingStepOne";
import ShopifyOnboardingStepTwo from "views/ShopifyOnboardingView/ShopifyOnboardingStepTwo";
import ShopifyOnboardingQuestionsStep from "views/ShopifyOnboardingView/ShopifyOnboardingQuestionsStep";
import OnboardingPaymentStep from "./steps/OnboardingB/OnboardingPaymentStep";
import OnboardingPickPlanStep from "./steps/OnboardingB/OnboardingPickPlanStep";
import OnboardingQuestionsStep from "./steps/OnboardingB/OnboardingQuestionsStep";
import OnboardingSuccessStep from "./steps/OnboardingB/OnboardingStepFiveB";
import OnboardingStepSixB from "./steps/OnboardingB/OnboardingStepSixB";

// for future implementation - need to check with backend if possible, seems
// like we save onboarding_step as a number in the backend, hard to use number index easy to make mistakes.

// const ONBOARDING_STEP_B = {
//   [ONBOARDING_FLOW.ONBOARDING_QUESTION_STEP]: <OnboardingQuestionsStep />,
//   [ONBOARDING_FLOW.ONBOARDING_PICK_PLAN_STEP]: <OnboardingPickPlanStep />,
//   [ONBOARDING_FLOW.ONBOARDING_PAYMENT_STEP]: <OnboardingPaymentStep />,
//   [ONBOARDING_FLOW.ONBOARDING_SUCCESS_STEP]: <OnboardingSuccessStep />,
// };

const ONBOARDING_STEPS_V1 = {
  1: <OnboardingPickPlanStep />,
  2: <OnboardingPaymentStep />,
  3: <OnboardingSuccessStep />,
  4: <OnboardingStepSixB />,
};

const ONBOARDING_STEPS_B = {
  1: <OnboardingQuestionsStep />,
  2: <OnboardingPickPlanStep />,
  3: <OnboardingPaymentStep />,
  4: <OnboardingSuccessStep />,
  5: <OnboardingStepSixB />,
};

const ONBOARDING_SHOPIFY_STEPS_V1 = {
  1: <ShopifyOnboardingStepOne />,
  2: <ShopifyOnboardingStepTwo />,
};

const ONBOARDING_SHOPIFY_STEPS = {
  1: <ShopifyOnboardingStepOne />,
  2: <ShopifyOnboardingQuestionsStep />,
  3: <ShopifyOnboardingStepTwo />,
};

export default function OnboardingView() {
  const shop = useSelector((state) => state.profile.shop);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("onboarding", shop.onboarding_step);
    window.history.pushState("", "", `/?${urlParams.toString()}`);
  }, [shop]);

  if (
    shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY &&
    shop.config_flags?.SHOP_ONBOARDING_VERSION === "2"
  ) {
    return <div>{ONBOARDING_SHOPIFY_STEPS[shop.onboarding_step]}</div>;
  }
  if (
    shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY &&
    shop.config_flags?.SHOP_ONBOARDING_VERSION !== "2"
  ) {
    return <div>{ONBOARDING_SHOPIFY_STEPS_V1[shop.onboarding_step]}</div>;
  }

  if (shop.config_flags?.SHOP_ONBOARDING_VERSION !== "2") {
    return (
      <div>
        <Helmet>
          <title>Welcome - Blanka</title>
          <meta
            name="description"
            content="Official app login page app for Shopify, Wix, WooCommerce and more"
          />
        </Helmet>
        {ONBOARDING_STEPS_V1[shop.onboarding_step]}
      </div>
    );
  }
  return (
    <div>
      <Helmet>
        <title>Welcome - Blanka</title>
        <meta
          name="description"
          content="Official app login page app for Shopify, Wix, WooCommerce and more"
        />
      </Helmet>
      {ONBOARDING_STEPS_B[shop.onboarding_step]}
    </div>
  );
}
