export const BRAND_PLATES_TYPES = {
  ONE_COLOR: "1 Color",
  TWO_COLOR: "2 Color",
  MULTI_COLOR: "Multi-color",
};

export const BRAND_PLATES_ALT_ATTRIBUTES = [
  "One color logo",
  "two color logo",
  "Multi color logo",
];

export const BRAND_PLATE_STEPS = [
  {
    title: "Visualize Your Brand",
  },
  {
    title: "Brand Plate Ready",
  },
  {
    title: "Ship Branded Products",
  },
];

export const CATEGORY = "all";
export const PRODUCT_TYPE = "BRAND_PLATE";
export const PAGE = 1;

export const BRAND_PLATE_STATUS = {
  PAYMENT_REQUIRED: ["PAYMENT_REQUIRED", "Payment required"],
  WAITING_FOR_LOGO: ["WAITING_FOR_LOGO", "Waiting for logo"],
  IN_PROGRESS: ["IN_PROGRESS", "In progress"],
  IN_REVISION: ["IN_REVISION", "In Revision"],
  PENDING_APPROVAL: ["PENDING_APPROVAL", "Pending Approval"],
  MOCKUP_ACCEPTED: ["MOCKUP_ACCEPTED", "Mockup Accepted"],
  APPROVED: ["APPROVED", "Approved"],
  DENIED: ["DENIED", "Denied"],
};

export const BRAND_PLATE_STATUS_V2 = {
  UPLOAD_YOUR_LOGO: ["UPLOAD_YOUR_LOGO", "Waiting for logo"],
  IN_PROGRESS: ["IN_PROGRESS", "In progress"],
  IN_REVISION: ["IN_REVISION", "In Revision"],
  PENDING_APPROVAL: ["PENDING_APPROVAL", "Pending Approval"],
  MOCKUP_ACCEPTED: ["MOCKUP_ACCEPTED", "Mockup Accepted"],
  APPROVED: ["APPROVED", "Approved"],
  DENIED: ["DENIED", "Denied"],
};

export const BRAND_PLATE_PROCESS = [
  {
    title: "Upload Your Logo",
    subtitle: "Upload the logo you want to use for your brand plate",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.UPLOAD_YOUR_LOGO[0],
  },
  {
    title: "In Progress",
    subtitle: "Our team will review your files to create product mock-ups",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.IN_PROGRESS[0],
  },
  {
    title: "In Revision",
    subtitle: "",
    status: "current",
    hidden: true,
    id: BRAND_PLATE_STATUS.IN_REVISION[0],
  },
  {
    title: "Pending Review",
    subtitle: "Please review your pending Brand Plate mockups",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.PENDING_APPROVAL[0],
  },
  {
    title: "Mockup Accepted",
    subtitle: "",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.MOCKUP_ACCEPTED[0],
  },
  {
    title: "Approved",
    subtitle: "Your Brand Plate is ready!",
    status: "current",
    id: BRAND_PLATE_STATUS_V2.APPROVED[0],
  },
];

// ID's for the Brand Plate Statuses, needed in the status indicator
export const STATUSES = [
  {
    title: "Payment Received",
    status: "completed",
    id: BRAND_PLATE_STATUS.PAYMENT_REQUIRED[0],
  },
  {
    title: "Waiting for Logo",
    subtitle: "Upload the logo you want to use for your brand plate",
    status: "current",
    id: BRAND_PLATE_STATUS.WAITING_FOR_LOGO[0],
  },
  {
    title: "In Progress",
    subtitle: "Our team will review your files to create product mock-ups",
    status: "current",
    id: BRAND_PLATE_STATUS.IN_PROGRESS[0],
  },
  {
    title: "In Revision",
    subtitle: "",
    status: "current",
    hidden: true,
    id: BRAND_PLATE_STATUS.IN_REVISION[0],
  },
  {
    title: "Pending Review",
    subtitle: "Please review your pending Brand Plate mockups",
    status: "current",
    id: BRAND_PLATE_STATUS.PENDING_APPROVAL[0],
  },
  {
    title: "Mockup Accepted",
    subtitle: "",
    status: "current",
    id: BRAND_PLATE_STATUS.MOCKUP_ACCEPTED[0],
  },
  {
    title: "Approved",
    subtitle: "Your Brand Plate is ready!",
    status: "current",
    id: BRAND_PLATE_STATUS.APPROVED[0],
  },
];
