import BlankaMyLogoDrop from "assets/images/blanka-my-logo-drop.png";
import BlankaMyLogoPreview from "assets/images/blanka-my-logo-preview.png";
import BlankaMyLogoThinLightPreview from "assets/images/blanka-my-logo-thin-light-preview.png";
import BlankaMyLogoThinDarkPreview from "assets/images/blanka-my-logo-thin-dark-preview.png";

export const steps = [
  {
    title: "Upload your logo",
    subTitle: "Used on light products",
    innerSteps: [{ label: "" }],
  },
  {
    title: "Additional logos",
    subTitle: "OTHER COLORS & SIZES (OPTIONAL)",
    innerSteps: [
      { label: "Dark product preview" },
      { label: "Thin dark product preview" },
      { label: "Thin light product preview" },
    ],
  },
  {
    title: "Review mockups",
    subTitle: "Preview your branded products",
    innerSteps: [{ label: "" }],
  },
];

export const LOGO_DEFAULT = "DEFAULT";
export const LOGO_INVERTED = "INVERTED";
export const LOGO_THIN = "THIN";
export const LOGO_INVERTED_THIN = "INVERTED_THIN";

export const mockups = [
  {
    image: BlankaMyLogoDrop,
    caption: "Light products",
    logo_type: LOGO_DEFAULT,
  },
  {
    image: BlankaMyLogoPreview,
    caption: "Dark products",
    logo_type: LOGO_INVERTED,
  },
  {
    image: BlankaMyLogoThinLightPreview,
    caption: "Thin light products",
    logo_type: LOGO_THIN,
  },
  {
    image: BlankaMyLogoThinDarkPreview,
    caption: "Thin dark products",
    logo_type: LOGO_INVERTED_THIN,
  },
];

export const LOGO_ERROR_MESSAGES = [
  "Accepted file formats: PNG w/ no background, JPG",
  "Min size 500px x 500px (recommended 1500px)",
  "Max size 5000px x 4000px (recommended 1500px)",
  "Max file size: 2mb",
];
