import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import queryClient from "api/queryClient";
import PublishedDiscontinuedProductNotification from "components/Notifications/PublishedDiscontinuedProductNotification/PublishedDiscontinuedProductNotification";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ErrorView from "views/ErrorView";
import Main from "views/Main";
import "./App.scss";

import DowngradeView from "views/DowngradeView";

import { GoogleOAuthProvider } from "@react-oauth/google";

import ScrollToTop from "components/ScrollToTop";
import { publicRoutes, routes } from "./routes";
import { theme } from "./theme";

function App() {
  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <QueryClientProvider client={queryClient}>
            <div className="App" data-testid="App">
              <ErrorBoundary
                FallbackComponent={ErrorView}
                onReset={() => {
                  window.location.reload();
                }}
              >
                <BrowserRouter>
                  <ScrollToTop />
                  <Routes>
                    {/* Special Routes */}
                    <Route
                      key="downgrade"
                      path="/downgrade/*"
                      element={<DowngradeView />}
                    />
                    {/* <Route
                      key="brand-plate"
                      path="/brand-plate/*"
                      element={<BrandPlateView />}
                    /> */}
                    <Route
                      element={
                        <Main
                          loggedIn={useSelector(
                            (state) => state.session.isLoggedIn
                          )}
                        />
                      }
                    >
                      {routes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          element={route.component}
                        />
                      ))}
                    </Route>

                    {publicRoutes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.component}
                      />
                    ))}

                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </BrowserRouter>
              </ErrorBoundary>
            </div>
          </QueryClientProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
