import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function BlankaFormulatorStepOneIcon(props) {
  return (
    <SvgIcon
      {...props}
      className="icon"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M26 17V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28H7C6.73478 28 6.48043 27.8946 6.29289 27.7071C6.10536 27.5196 6 27.2652 6 27V17C6 15.9391 6.42143 14.9217 7.17157 14.1716C7.92172 13.4214 8.93913 13 10 13H22C23.0609 13 24.0783 13.4214 24.8284 14.1716C25.5786 14.9217 26 15.9391 26 17Z"
        fill="#246071"
      />
      <path
        d="M23 12.1V11C23 9.93913 22.5786 8.92172 21.8284 8.17157C21.0783 7.42143 20.0609 7 19 7H17V4H21C21.2652 4 21.5196 4.10536 21.7071 4.29289C21.8946 4.48043 22 4.73478 22 5C22 5.26522 22.1054 5.51957 22.2929 5.70711C22.4804 5.89464 22.7348 6 23 6C23.2652 6 23.5196 5.89464 23.7071 5.70711C23.8946 5.51957 24 5.26522 24 5C24 4.20435 23.6839 3.44129 23.1213 2.87868C22.5587 2.31607 21.7956 2 21 2H13C12.7348 2 12.4804 2.10536 12.2929 2.29289C12.1054 2.48043 12 2.73478 12 3C12 3.26522 12.1054 3.51957 12.2929 3.70711C12.4804 3.89464 12.7348 4 13 4H15V7H13C11.9391 7 10.9217 7.42143 10.1716 8.17157C9.42143 8.92172 9 9.93913 9 11V12.1C7.87139 12.3321 6.8573 12.9461 6.12866 13.8387C5.40003 14.7313 5.00141 15.8478 5 17V27C5 27.5304 5.21071 28.0391 5.58579 28.4142C5.96086 28.7893 6.46957 29 7 29H25C25.5304 29 26.0391 28.7893 26.4142 28.4142C26.7893 28.0391 27 27.5304 27 27V17C26.9986 15.8478 26.6 14.7313 25.8713 13.8387C25.1427 12.9461 24.1286 12.3321 23 12.1ZM13 9H19C19.5304 9 20.0391 9.21071 20.4142 9.58579C20.7893 9.96086 21 10.4696 21 11V12H11V11C11 10.4696 11.2107 9.96086 11.5858 9.58579C11.9609 9.21071 12.4696 9 13 9ZM25 27H7V17C7 16.2044 7.31607 15.4413 7.87868 14.8787C8.44129 14.3161 9.20435 14 10 14H22C22.7956 14 23.5587 14.3161 24.1213 14.8787C24.6839 15.4413 25 16.2044 25 17V27Z"
        fill="#246071"
      />
    </SvgIcon>
  );
}

export default BlankaFormulatorStepOneIcon;
