import { useDispatch, useSelector } from "react-redux";
import {
  onSetChangeModalProps,
  toggleChangePlanModal,
} from "state/changePlanModalSlice";

const useChangePlanModal = () => {
  const isOpen = useSelector((state) => state.changePlanModal.isOpen);
  const { title, subtitle, isFreePlanLimit, freePlanLimitSubText, onSuccess } =
    useSelector((state) => state.changePlanModal.planProps);
  const dispatch = useDispatch();

  const onCloseModal = () => {
    dispatch(toggleChangePlanModal(false));
  };

  const onOpenModal = (includedProps) => {
    if (includedProps) {
      dispatch(onSetChangeModalProps(includedProps));
    }

    dispatch(toggleChangePlanModal(true));
  };

  return {
    isOpen,
    title,
    subtitle,
    isFreePlanLimit,
    freePlanLimitSubText,
    onSuccess,
    onCloseModal,
    onOpenModal,
  };
};

export default useChangePlanModal;
