import {
  BlankaFormulatorIcon,
  CustomerOrderIcon,
  FindProductIcon,
  InventoryOrderIcon,
  MyAccountIcon,
  MyBrandPlateIcon,
  MyLogoIcon,
  MyProductsIcon,
  SampleOrdersIcon,
} from "components/Icons";
import React, { lazy } from "react";

import AccountBillingUpdateView from "views/AccountBillingUpdateView";
import AccountBillingView from "views/AccountBillingView";
import AccountPasswordView from "views/AccountPasswordView";
import SignupView from "views/SignupView";

import AccountPasswordUpdateView from "views/AccountPasswordUpdateView";
import AccountStoreIntegrationView from "views/AccountStoreIntegrationView";
import BrandPlateSetupView from "views/BrandPlateSetupView";
import CartView from "views/CartView";
import CheckoutCompleteView from "views/CheckoutCompleteView";
import CheckoutPaymentView from "views/CheckoutPaymentView";
import CheckoutShippingView from "views/CheckoutShippingView";
import Login from "views/Login";
import LogoutView from "views/LogoutView";
import PasswordResetView from "views/PasswordResetView";
import ShopifyLogin from "views/ShopifyLogin";
import WixLogin from "views/WixLogin";
import Woocommerce from "views/Woocommerce";

import AccountPersonalInformation from "views/AccountPersonalInformationView/AccountPersonalInformationView";
import PasswordResetUpdateView from "views/PasswordResetUpdateView";

import AccountAddressesUpdateView from "views/AccountAddressesUpdateView/AccountAddressesUpdateView";
import AccountAddressesView from "views/AccountAddressesView/AccountAddressesView";
import BlankaFormulatorView from "views/BlankaFormulatorView";
import ProductView from "views/ProductView";

const FindProductsView = lazy(() => import("views/FindProductsView"));
const MyProductsView = lazy(() => import("views/MyProductsView"));
const InventoryOrdersView = lazy(() => import("views/InventoryOrdersView"));
const CustomerOrdersView = lazy(() => import("views/CustomerOrdersView"));
const SampleOrdersView = lazy(() => import("views/SampleOrdersView"));
const BrandPlateView = lazy(() => import("views/BrandPlateView"));
const AccountView = lazy(() => import("views/AccountView"));
const BlankaLabs = lazy(() => import("views/BlankaFormulatorView"));
const BrandPlatePastOrdersView = lazy(
  () => import("views/BrandPlatePastOrdersView")
);
const SearchView = lazy(() => import("views/SearchView"));

export const blankaLabs = "blanka Labs";
export const routes = [
  {
    name: "Find Products",
    path: "/",
    component: <FindProductsView />,
    menu: true,
    icon: <FindProductIcon />,
  },
  {
    name: "Search",
    path: "/products/search",
    component: <SearchView />,
    // icon: <FindProductIcon />,
  },
  {
    name: "Product",
    path: "/products/:sku",
    component: <ProductView />,
    icon: <FindProductIcon />,
  },
  {
    name: "My Brand Plate",
    path: "/brand-plate/",
    component: <BrandPlateView />,
    menu: true,
    icon: <MyBrandPlateIcon />,
  },
  {
    name: "My Brand Plate",
    path: "/brand-plate/setup",
    component: <BrandPlateSetupView />,
    // menu: true,
    // icon: <MyBrandPlateIcon />,
  },
  {
    name: "My Brand Plate",
    path: "/brand-plate/past-orders",
    component: <BrandPlatePastOrdersView />,
    menu: false,
    icon: <MyBrandPlateIcon />,
  },
  {
    name: "My Products",
    path: "/my-products",
    component: <MyProductsView />,
    menu: true,
    icon: <MyProductsIcon />,
  },
  {
    name: "Formulator",
    path: "/blanka-formulator",
    component: <BlankaFormulatorView />,
    menu: true,
    icon: <BlankaFormulatorIcon />,
  },

  {
    hasDividerTop: true,
    sectionTitle: "orders",
    name: "Sample Orders",
    path: "/sample-orders",
    component: <SampleOrdersView />,
    menu: true,
    icon: <SampleOrdersIcon />,
  },
  {
    name: "Customer Orders",
    path: "/orders",
    component: <CustomerOrdersView />,
    menu: true,
    icon: <CustomerOrderIcon />,
    isParent: true,
  },
  {
    name: "Inventory Orders",
    path: "/inventory-orders",
    component: <InventoryOrdersView />,
    menu: true,
    icon: <InventoryOrderIcon />,
  },
  {
    hasDividerTop: true,
    sectionTitle: "my account",
    name: "Profile",
    path: "/profile",
    component: <AccountView />,
    menu: true,
    icon: <MyAccountIcon />,
  },
  {
    sectionTitle: "my account",
    name: "Addresses",
    path: "/profile/addresses",
    component: <AccountAddressesView />,
    menu: false,
  },
  {
    sectionTitle: "my account",
    name: "Update Addresses",
    path: "/profile/addresses/update",
    component: <AccountAddressesUpdateView />,
    menu: false,
  },
  {
    sectionTitle: "my account",
    name: "Update Addresses",
    path: "/profile/addresses/new",
    component: <AccountAddressesUpdateView />,
    menu: false,
  },
  {
    name: "My Account",
    path: "/profile/billing",
    component: <AccountBillingView />,
    menu: false,
    icon: <MyAccountIcon />,
  },
  {
    name: "My Account",
    path: "/profile/billing/update",
    component: <AccountBillingUpdateView />,
    menu: false,
    icon: <MyAccountIcon />,
  },
  {
    name: "My Account",
    path: "/profile/password",
    component: <AccountPasswordView />,
    menu: false,
    icon: <MyAccountIcon />,
  },
  {
    name: "My Account",
    path: "/profile/password/update",
    component: <AccountPasswordUpdateView />,
    menu: false,
    icon: <MyAccountIcon />,
  },
  {
    name: "My Account",
    path: "/profile/connect",
    component: <AccountStoreIntegrationView />,
    menu: false,
    icon: <MyAccountIcon />,
  },
  {
    name: "My Account",
    path: "/profile/information",
    component: <AccountPersonalInformation />,
    menu: false,
    icon: <MyAccountIcon />,
  },

  {
    name: "Logout",
    path: "/logout",
    component: <LogoutView />,
    menu: false,
  },
  {
    name: "Cart",
    path: "/cart",
    component: <CartView />,
    menu: false,
    icon: "account_box",
  },
  {
    name: "Checkout Shipping",
    path: "/checkout/shipping",
    component: <CheckoutShippingView />,
    menu: false,
  },
  {
    name: "Checkout Billing",
    path: "/checkout/payment",
    component: <CheckoutPaymentView />,
    menu: false,
  },
  {
    name: "Checkout Success",
    path: "/checkout/thank-you",
    component: <CheckoutCompleteView />,
    menu: false,
  },
  {
    name: "My Logo",
    path: "my-logo",
    menu: true,
    // component: <MyLogoView />,
    icon: <MyLogoIcon />,
  },
  {
    name: "Search",
    path: "/search",
    component: <SearchView />,
    menu: false,
  },
];

export const publicRoutes = [
  {
    name: "Login",
    path: "login",
    menu: false,
    component: <Login />,
  },
  {
    name: "Shopify Login",
    path: "login/shopify",
    menu: false,
    component: <ShopifyLogin />,
  },
  {
    name: "Woocommerce Connect",
    path: "login/woocommerce",
    menu: false,
    component: <Woocommerce />,
  },
  {
    name: "Wix Login",
    path: "login/wix",
    menu: false,
    component: <WixLogin />,
  },
  {
    name: "Signup",
    path: "signup",
    menu: false,
    component: <SignupView />,
  },

  {
    name: "Password Reset",
    path: "password-reset",
    component: <PasswordResetView />,
  },

  {
    name: "Password Reset",
    path: "password-set",
    component: <PasswordResetUpdateView />,
  },
];
