import React from "react";

export default function CloseButton({ closeToast }) {
  return (
    <i
      className="text--nunito notifications__close-button"
      onClick={closeToast}
    >
      [x] close
    </i>
  );
}
