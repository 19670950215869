/* eslint-disable react/no-array-index-key */
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import blankaLogo from "assets/images/logo.png";
import AppBar from "components/AppBar";
import { HelpIcon, MarketingIcon } from "components/Icons";
import Text from "components/Text";
import { useAnalytics } from "hooks";
import { useAbTests } from "hooks/useABTests";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { setIsOpen } from "state/myLogoSlice";

import styles from "./Sidebar.module.scss";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: drawerWidth,
  [theme.breakpoints.up("sm")]: {
    width: drawerWidth,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ hasHeaderBar }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const abTests = useAbTests();
  const [dynamicRoutes, setDynamicRoutes] = useState(routes);

  const handleDrawerOpen = () => {
    setOpen(true);
    // onOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    // onOpenDrawer(false);
  };

  const handleOpenLinkNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleLinkClick = (e, route) => {
    if (route.path === "my-logo") {
      dispatch(setIsOpen(true));
      setOpen(false);
    }
    if (route.external) {
      e.preventDefault();
      handleOpenLinkNewTab(route.path);
    }
    analytics.sendEvent(analytics.SIDEBAR_NAVIGATION, { path: route.name });
  };

  useEffect(() => {
    // Check if the URL contains the string "my-logo"
    if (window.location.href.includes("my-logo")) {
      setOpen(false);
      navigate("/");
    }
  }, []);

  return (
    <Box
      sx={{ display: "flex" }}
      className={`sidebar ${hasHeaderBar ? styles.hasHeader : ""}`}
    >
      <AppBar open handleDrawerOpen={handleDrawerOpen} />

      <Drawer variant="permanent" open={open} className={styles.drawer}>
        <DrawerHeader className={styles.drawerHead}>
          <img src={blankaLogo} alt="blanka" className={styles.blankaLogo} />
          <IconButton onClick={handleDrawerClose} style={{ display: "none" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <List className={styles.submenu}>
          {dynamicRoutes
            .filter((route) => route.menu)
            .map((route, index) => (
              <div
                key={index}
                className={`navlink-${route?.name.replace(/\s+/g, "-").toLocaleLowerCase()}`}
              >
                {route.sectionTitle && (
                  <Text
                    fontSize={9}
                    variant="body1"
                    color="gray"
                    className={` text--upper text--spacing-2-4 mt-25 ml-5`}
                  >
                    {route.sectionTitle}
                  </Text>
                )}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "navItem activeItem" : "navItem"
                  }
                  to={route.path}
                  style={{ textDecoration: "none" }}
                  key={index}
                  onClick={(e) => handleLinkClick(e, route)}
                  data-cy={`${route.path}`}
                >
                  <ListItem button key={route.name}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.name} />
                    <Tooltip title="VIP">
                      <ListItemIcon tooltip="VIP">
                        {route.tag && route.tag}
                      </ListItemIcon>
                    </Tooltip>
                  </ListItem>
                </NavLink>

                {route.hasDividerBottom && <Divider />}
              </div>
            ))}

          <NavLink
            className="navItem"
            to="#"
            style={{ textDecoration: "none" }}
            key={999}
            onClick={() => {
              analytics.sendEvent(analytics.SIDEBAR_NAVIGATION, {
                path: "marketing",
              });
              window.open(
                "https://blankabrand.com/collections/marketing-creative?utm_source=blanka&utm_medium=app&utm_campaign=marketing-creative"
              );
            }}
          >
            <ListItem button key="marketing">
              <ListItemIcon>
                <MarketingIcon />
              </ListItemIcon>
              <ListItemText primary="Marketing" />
            </ListItem>
          </NavLink>

          <NavLink
            className="navItem"
            to="#"
            style={{ textDecoration: "none" }}
            key={99}
            onClick={() => {
              analytics.sendEvent(analytics.SIDEBAR_NAVIGATION, {
                path: "faq",
              });
              window.open(
                "http://faq.blankabrand.com/?utm_source=blanka&utm_medium=app&utm_campaign=faq"
              );
            }}
          >
            <ListItem button key="help">
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItem>
          </NavLink>
        </List>
      </Drawer>
    </Box>
  );
}
