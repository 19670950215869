import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { SMALL } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import { BRAND_PLATE_STATUS } from "constants/brandPlate";
import styles from "./BrandPlateStatusProcess.module.scss";

function BrandPlateStatusProcess({ steps, currentStep, style }) {
  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const filteredSteps = steps.filter(
    (step) =>
      step.id !== BRAND_PLATE_STATUS.IN_REVISION[0] || step.id === currentStep
  );

  // Calculate the active step index
  const activeStep = filteredSteps.findIndex((step) => step.id === currentStep);

  return (
    <Box style={style}>
      <Typography
        mb={1}
        className={styles.brandPlateStatusProcessTitle}
        variant="h3"
      >
        Process
      </Typography>
      <Box className={styles.brandPlateStatusProcess}>
        <Stepper
          activeStep={activeStep}
          orientation={isMobile ? "horizontal" : "vertical"}
          classes={{
            root: styles.stepperRoot,
            vertical: styles.stepperVertical,
          }}
          connector={
            <StepConnector classes={{ line: styles.stepConnectorLine }} />
          }
        >
          {filteredSteps.map((step) => (
            <Step key={step.title}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: styles.stepIconRoot,
                    active: styles.stepIconActive,
                    completed: styles.stepIconCompleted,
                    error: styles.stepIconError,
                  },
                }}
                classes={{
                  root: styles.stepLabelRoot,
                  active: styles.stepLabelActive,
                  completed: styles.stepLabelCompleted,
                  error: styles.stepLabelError,
                  alternativeLabel: styles.stepLabelAlternativeLabel,
                }}
              >
                {step.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
}

BrandPlateStatusProcess.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
    })
  ).isRequired,
  currentStep: PropTypes.string.isRequired,
};

export default BrandPlateStatusProcess;
