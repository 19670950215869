import React from "react";

import Button from "components/Buttons/Button";
import Text from "components/Text";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export function GenericSuccessNotification({ text, title }) {
  return (
    <div>
      <span className="toast-success-text text--bold">
        {title || "Success!"}
      </span>

      <span className="toast-content-text">{text}</span>
    </div>
  );
}

export function GenericErrorNotification({ text, title }) {
  return (
    <div>
      <Text
        className="toast-error-text text--bold mb-20 "
        variant="body1"
        fontSize={16}
      >
        {title || "OOPS!"}
      </Text>

      <Text variant="body1" fontSize={16} className="toast-content-text">
        {text || "An error occurred. Contact us to resolve this."}
      </Text>
    </div>
  );
}

export function InventoryAddedNotification() {
  return (
    <div>
      <span className="toast-success-text">Success!</span>
      <span className="toast-content-text">
        This inventory has been added to your{" "}
      </span>
      <Link className="toast-link" to="/cart">
        Cart.
      </Link>
    </div>
  );
}

export function BlankaLabsAddedNotification() {
  return (
    <div>
      <span className="toast-success-text">Success!</span>
      <span className="toast-content-text">
        This Blanka Labs product has been added to your{" "}
      </span>
      <Link className="toast-link" to="/cart">
        Cart.
      </Link>
    </div>
  );
}

export const addSampleToCartSuccess = () => (
  <div>
    <span className="toast-success-text text--bold">Success!</span>

    <span className="toast-content-text">Sample has been added to your </span>

    <Link className="toast-link" to="/cart">
      Cart.
    </Link>
  </div>
);

export function visulizationInProgress() {
  return (
    <div>
      <p className="toast-content-text">
        Hang tight! We&apos;re creating your custom product images. You&apos;ll
        receive an email when they are complete.
      </p>
    </div>
  );
}

export const brandPlateSuccess = () => (
  <div>
    <span className="toast-success-text">Success!</span>

    <span className="toast-content-text">
      Your Brand Plate has been ordered.
    </span>
  </div>
);

export function AddToStoreSuccess({ product }) {
  return (
    <div>
      <span className="text--bold toast-success-text">Success!</span>

      <span className="toast-content-text">
        {product} has been added to MY PRODUCTS.
      </span>
    </div>
  );
}

export const publishToStoreSuccess = () => (
  <div>
    <span className="toast-success-text">Success!</span>

    <span className="toast-content-text">Product Published.</span>
  </div>
);

export function ServerError({ message }) {
  return (
    <div>
      <span className="toast-error -text">Error</span>

      <span className="toast-content-text">{message}</span>
    </div>
  );
}

export function AuthenticationError() {
  return (
    <div>
      <h2 className="toast-error-text">Authentication Error!</h2>

      <p className="toast-content-text">Please Login Again.</p>
    </div>
  );
}

export function NetworkError() {
  return (
    <div>
      <h2 className="toast-error-text">Error!</h2>

      <p className="toast-content-text">Could not connect to server.</p>
    </div>
  );
}

export function InventoryWithSampleError() {
  return (
    <div>
      <Text
        variant="body1"
        fontSize={20}
        color="white"
        className="toast-error-text text--upper mb-25"
      >
        Oops!
      </Text>

      <p className="toast-content-text">
        <span className="text--bold">INVENTORY ORDERS</span>,{" "}
        <span className="text--bold">BLANKA LABS ORDERS</span> OR{" "}
        <span className="text--bold">SAMPLE ORDERS</span> can&apos;t be ordered
        at the same time. Please clear your cart to continue.
      </p>
    </div>
  );
}

export function ClearCartError() {
  return (
    <div>
      <Text
        variant="body1"
        fontSize={20}
        color="white"
        className="toast-error-text text--upper mb-25"
      >
        Oops!
      </Text>

      <p className="toast-content-text">Please clear your cart to continue.</p>
    </div>
  );
}
export function AddItemToCartError(err) {
  const errorMsg = `Error adding item to cart: ${err}`;
  return (
    <div>
      <Text
        variant="body1"
        fontSize={20}
        color="white"
        className="toast-error-text text--upper mb-25"
      >
        Oops!
      </Text>

      <p className="toast-content-text">{errorMsg}</p>
    </div>
  );
}

export function NoLogoError() {
  return (
    <div>
      <Text
        variant="body1"
        fontSize={20}
        color="white"
        className="toast-error-text text--upper mb-25"
      >
        Oops!
      </Text>

      <Text
        fontSize={16}
        variant="body1"
        color="white"
        className="toast-content-text mb-25"
      >
        You need to upload a logo first!
      </Text>

      <Link className="toast-link" to="/profile?action=upload-logo">
        Click here to upload one.
      </Link>
    </div>
  );
}

export function NoStoreConnectionError() {
  return (
    <div>
      <Text
        variant="body1"
        fontSize={20}
        color="white"
        className="toast-error-text text--upper text--bold mb-25"
      >
        Oops!
      </Text>

      <p className="toast-error-content">
        You need to{" "}
        <span className="text--upper text--bold">connect your store</span>{" "}
        before publishing products
      </p>
      <Link className="toast-link text--no-underline" to="/profile/connect">
        <Button onClick={() => null} className="mt-20" color="secondary">
          Connect your Store
        </Button>
      </Link>
    </div>
  );
}

export function NoBillingMethodError() {
  return (
    <div>
      <Text
        className="toast-error-text text--bold mb-20"
        variant="body1"
        fontSize={16}
      >
        OOPS!
      </Text>

      <Text className="toast-error-content mb-20" variant="body1" fontSize={18}>
        You need to upload billing details first! Click here to add your
        details.
      </Text>
      <Link
        className="toast-link text--no-underline"
        to="/profile/billing/update"
      >
        <Button color="secondary" variant="contained" className="button__link">
          Update Payment Info
        </Button>
      </Link>
    </div>
  );
}

export function SampleWithInventoryError() {
  return (
    <div>
      <Text
        className="toast-error-text text--bold mb-20 "
        variant="body1"
        fontSize={16}
      >
        OOPS!
      </Text>

      <p className="toast-content-text">
        You can&apos;t order samples and inventory at the same time. Please
        clear your shopping bag to continue.
      </p>
    </div>
  );
}

export function SampleKitError() {
  return (
    <div>
      <Text
        className="toast-error-text text--bold mb-20 "
        variant="body1"
        fontSize={16}
      >
        OOPS!
      </Text>

      <p className="toast-content-text">
        sample kits are only available for
        <span className="text--bold">SAMPLE ORDERS.</span>
      </p>
    </div>
  );
}

export const notifications = {
  noStoreConnectedError() {
    toast.error(<NoStoreConnectionError />, { autoClose: false });
  },
  noBillingMethodError() {
    toast.error(NoBillingMethodError);
  },
  showErrorMessage(text) {
    toast.error(<GenericErrorNotification text={text} />, {
      toastId: "error-generic",
    });
  },
};
