import { Box, Button, Container, Grid, Paper } from "@mui/material";
import PageHeader from "components/PageHeader";
import Text from "components/Text";
import React from "react";

import BlankaFormulatorStepFourIcon from "components/Icons/BlankaFormulatorStepFourIcon";
import BlankaFormulatorStepOneIcon from "components/Icons/BlankaFormulatorStepOneIcon";
import BlankaFormulatorStepThreeIcon from "components/Icons/BlankaFormulatorStepThreeIcon";
import BlankaFormulatorStepTwoIcon from "components/Icons/BlankaFormulatorStepTwoIcon";

import BlankaLabsLogoImg from "assets/images/blanka_labs_logo.png";
import FormulatorImage from "assets/images/formulator_image.webp";
import BlankaFormulatorLogoImg from "assets/images/logo-blanka-formulator.svg";
import { HUBSPOT_CALENDAR_URL, TYPEFORM_URL } from "constants/externalLinks";
import { useAnalytics, useProfile } from "hooks";

import useChangePlanModal from "hooks/useChangePlanModal";
import styles from "./BlankaFormulatorView.module.scss";

function BlankaFormulatorView() {
  const profileHook = useProfile();
  const analytics = useAnalytics();
  const { profile, shop } = profileHook;
  const { onOpenModal } = useChangePlanModal();

  const getTypeFormUrl = () => {
    const url = `${TYPEFORM_URL}?user_id=${profile.id}&email=${profile.email}&first_name=${encodeURIComponent(profile.first_name)}&last_name=${encodeURIComponent(profile.last_name)}&shop_id=${shop.id}`;
    return url;
  };

  const handleTypeformLinkClick = () => {
    if (!profileHook.isFreePlan()) {
      window.open(getTypeFormUrl(), "_blank");
      analytics.sendEvent(analytics.BLANKA_FORMULATOR_OPEN_TYPEFORM, {});
    } else {
      onOpenModal({ isFreePlanLimit: true });
      analytics.sendEvent(analytics.PAYWALL_HIT, {
        location: "blanka_labs_view",
        paywall_name: "blanka_labs_landing_page",
      });
    }
  };

  return (
    <div className={`${styles.blankaLabsView} blanka-labs-view`}>
      <PageHeader title="Blanka Formulator" />
      <Container maxWidth="l" className={styles.blankaLabsContainer}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className={styles.alignCenter}
          >
            <Box textAlign="center" className={styles.infoBox}>
              <img
                className={styles.productImg}
                src={FormulatorImage}
                alt="Blanka Formulator Products"
              />
              <img
                className={styles.logo}
                src={BlankaFormulatorLogoImg}
                alt="Blanka Formulator Logo"
              />
              <Text variant="h1" fontSize={21} color="gray" gutterBottom>
                Create your own custom skincare
              </Text>
              <Text variant="body1" fontSize={16} gutterBottom>
                Dive into the world of personalized skincare where you can
                create your own unique formulations.
              </Text>
              <Text
                variant="body2"
                fontSize={16}
                gutterBottom
                className="text--bold"
              >
                Start creating your custom products now by upgrading now!
              </Text>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleTypeformLinkClick()}
              >
                {profileHook.isFreePlan() ? "UPGRADE NOW" : "START CREATING"}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className="mt-20 mb-20">
            <Text
              variant="body1"
              color="gray"
              fontSize={12}
              className="mb-20 text--align-mobile-center"
            >
              HOW IT WORKS
            </Text>
            <Paper
              elevation={3}
              sx={{ padding: 2 }}
              className={styles.stepCard}
            >
              <div className={styles.stepLeft}>
                <Text color="secondary" variant="body1">
                  STEP <span className={styles.textNumber}>1</span>
                </Text>
                <BlankaFormulatorStepOneIcon />
              </div>
              <div className={styles.stepRight}>
                <Text color="secondary" variant="h3" fontSize={16}>
                  Select your product
                </Text>
                <ul className={styles.stepList}>
                  <li>Cleanser</li>
                  <li>Face Oil</li>
                  <li>Toner</li>
                  <li>Moisturizer</li>
                  <li>Serum</li>
                </ul>
              </div>
            </Paper>

            <Paper
              elevation={3}
              sx={{ padding: 2 }}
              className={styles.stepCard}
            >
              <div className={styles.stepLeft}>
                <Text color="secondary" variant="body1">
                  STEP <span className={styles.textNumber}>2</span>
                </Text>
                <BlankaFormulatorStepTwoIcon />
              </div>
              <div className={styles.stepRight}>
                <Text variant="h2" color="secondary" fontSize={16}>
                  Select active ingredients
                </Text>
                <ul className={styles.stepList}>
                  <li>Niacinamide</li>
                  <li>Hyaluronic Acid</li>
                  <li>Beta Glucan</li>
                  <li>Coffee Extract</li>
                  <li>Squalene</li>
                  <li>And more...</li>
                </ul>
              </div>
            </Paper>

            <Paper
              elevation={3}
              sx={{ padding: 2 }}
              className={styles.stepCard}
            >
              <div className={styles.stepLeft}>
                <Text variant="body1" color="secondary">
                  STEP <span className={styles.textNumber}>3</span>
                </Text>
                <BlankaFormulatorStepThreeIcon />
              </div>
              <div className={styles.stepRight}>
                <Text variant="h2" color="secondary" fontSize={16}>
                  Select your scents
                </Text>
                <ul className={styles.stepList}>
                  <li>Jasmine</li>
                  <li>Aloe</li>
                  <li>Rose Bouquet</li>
                  <li>Ruby Grapefruit Oil</li>
                  <li>And more...</li>
                </ul>
              </div>
            </Paper>

            <Paper
              elevation={3}
              sx={{ padding: 2 }}
              className={styles.stepCard}
            >
              <div className={styles.stepLeft}>
                <Text variant="body1" color="secondary">
                  STEP <span className={styles.textNumber}>4</span>
                </Text>
                <BlankaFormulatorStepFourIcon />
              </div>
              <div className={styles.stepRight}>
                <Text variant="h2" color="secondary" fontSize={16}>
                  Select packaging
                </Text>
                <ul className={styles.stepList}>
                  <li>Amber</li>
                  <li>Clear</li>
                  <li>Frosted</li>
                  <li>Black box</li>
                  <li>White box</li>
                  <li>and more...</li>
                </ul>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Box className={styles.flexContainer}>
        <Box className={styles.logoBox}>
          <img
            src={BlankaLabsLogoImg}
            className={styles.logo}
            alt="Blanka Labs Logo"
          />
        </Box>
        <Box className={styles.textContainer}>
          <Text variant="h2" className={styles.title}>
            Want to go even more custom?
          </Text>
          <Text className={styles.subtitle}>
            We offer more advanced custom formulations, packaging, and more with
            our Labs program.{" "}
            <a href={HUBSPOT_CALENDAR_URL} target="__blank">
              Contact Sales
            </a>{" "}
            to learn more.{" "}
          </Text>
        </Box>
      </Box>
    </div>
  );
}

export default BlankaFormulatorView;
