import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { LOGO_ERROR_MESSAGES } from "constants/generatorLogo";
import { useAnalytics } from "hooks";

function LogoDropzone({
  onFileAccepted,
  onFileError,
  children,
  onDropCallback,
  accept,
  maxSize,
  minSize,
  maxFiles,
}) {
  const analytics = useAnalytics();
  const onDrop = (acceptedFiles, fileRejections) => {
    // The current component only works with one file.
    // This callback is to support a generic behaviour and keep it
    // backward compatible.
    if (onDropCallback) {
      onDropCallback(acceptedFiles, fileRejections);
      return;
    }

    try {
      if (acceptedFiles.length > 0) {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;

          image.onload = () => {
            if (
              image.width < 300 ||
              image.height < 300 ||
              image.width > 5000 ||
              image.height > 4000
            ) {
              if (onFileError) {
                onFileError(LOGO_ERROR_MESSAGES);
              }
              return;
            }

            onFileAccepted(acceptedFiles[0]);
          };
        };

        reader.readAsDataURL(acceptedFiles[0]);
      }

      if (fileRejections.length > 0) {
        if (onFileError) {
          const rejectionItems = LOGO_ERROR_MESSAGES;

          onFileError(rejectionItems);
        }
      }

      if (fileRejections.length > 0) {
        if (onFileError) {
          const errorCodes = fileRejections.map((item) =>
            item.errors.map((error) => error.code)
          );
          analytics.sendEvent(analytics.UPLOAD_LOGO_ERROR, {
            errors: errorCodes,
          });

          const rejectionItems = LOGO_ERROR_MESSAGES;

          onFileError(rejectionItems);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize,
    minSize,
    onDrop,
    maxFiles,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}

LogoDropzone.propTypes = {
  onFileAccepted: PropTypes.func.isRequired,
  onFileError: PropTypes.func.isRequired,
  onDropCallback: PropTypes.func,
  accept: PropTypes.any,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  maxFiles: PropTypes.number,
};

LogoDropzone.defaultProps = {
  onDropCallback: undefined, // The default is undefined to keep backward compatibility
  accept: {
    "image/png": [".png"],
    "image/jpeg": [".jpeg", ".jpg"],
  },
  maxSize: 1048576 * 2, // 2MB
  minSize: 1024 * 20, // 20KB
  maxFiles: 15,
};

export default LogoDropzone;
