import { Box, Divider } from "@mui/material";
import { createSetupIntent, updatePlan, updateShop } from "api";
import UpdatePaymentStripe from "components/Modals/UpdatePaymentStripe";
import Text from "components/Text";
import {
  ANNUAL_PLAN_IDS,
  PLAN_INFO,
  PLAN_PRICING_TABLE_INFO,
} from "constants/plans";
import { useProfile } from "hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShop } from "state/profileSlice";
import OnboardingWrapperB from "../OnboardingWrapperB";
import SidePanelPlanSelectionB from "../components/SidePanelPlanSelectionB/SidePanelPlanSelectionB";
import styles from "./OnboardingPaymentStep.module.scss";

export default function OnboardingPaymentStep() {
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState("");
  const shop = useSelector((state) => state.profile.shop);
  const profileHook = useProfile();

  const [message] = useState(null);
  const [isAnnualSelected, setIsAnnualSelected] = useState(
    profileHook.isAnnualPlan()
  );
  const [selectedPlan, setSelectedPlan] = useState(shop.plan);

  const planPair = profileHook?.getPlanPair();

  useEffect(() => {
    if (!shop.plan) {
      return;
    }

    const getData = async () => {
      const response = await createSetupIntent(PLAN_INFO[shop.plan]?.price);
      setClientSecret(response.data.client_secret);
    };

    getData();
  }, []);

  const handleNextStep = async () => {
    await updatePlan(shop.plan);
    updateShop(shop.id, { onboarding_step: shop.onboarding_step + 1 }).then(
      (res) => {
        dispatch(setShop(res.data));
      }
    );
  };

  const handleBack = () => {
    updateShop(shop.id, { onboarding_step: shop.onboarding_step - 1 }).then(
      (res) => {
        dispatch(setShop(res.data));
      }
    );
  };

  const getNewSetupIntent = async (planId) => {
    setClientSecret("");
    const response = await createSetupIntent(PLAN_INFO[planId]?.price);
    setClientSecret(response.data.client_secret);
  };

  const onError = async () => {
    setClientSecret("");
    await getNewSetupIntent(shop.id);
  };

  const handlePlanSelection = async (plan) => {
    setIsAnnualSelected(plan.type === "annual");
    setSelectedPlan(plan.id);

    // Update the shop state with the new plan:
    const updatedShop = {
      ...shop,
      plan: plan.id,
      plan_type: plan.type,
    };
    dispatch(setShop(updatedShop));

    await getNewSetupIntent(updateShop.plan);
  };

  useEffect(() => {
    const fetchUpdatedShop = async () => {
      const response = await updateShop(shop.id, {
        plan: selectedPlan,
        plan_type: isAnnualSelected ? "ANNUAL" : "MONTHLY",
      });
      dispatch(setShop(response.data));
    };

    fetchUpdatedShop();
  }, [selectedPlan, isAnnualSelected, dispatch, shop.id]);

  return (
    <OnboardingWrapperB>
      <Box className={styles.sideBox}>
        <SidePanelPlanSelectionB
          plan={{
            planId: selectedPlan,
            planType: isAnnualSelected ? "ANNUAL" : "MONTHLY",
          }}
          planPair={planPair}
          onPrevious={handleBack}
          onPlanSelect={handlePlanSelection}
          isAnnualSelected={isAnnualSelected}
        />
      </Box>
      <Box className={styles.onboardingStepThreeB}>
        <Box className={styles.paymentContainer}>
          <Box className={styles.planDetailsContainer}>
            <Box className={styles.upsellContainer}>
              <Text variant="h1" className={styles.title}>
                Enjoy a 14-day FREE trial
              </Text>
              <Text variant="h1" className={styles.title}>
                $0
              </Text>
            </Box>
            <Box className={styles.upsellContainer}>
              <Text className={styles.subtitle}>
                Amount after trial ends on{" "}
                {moment().add(14, "days").format("MMM DD")}
              </Text>
              {ANNUAL_PLAN_IDS.includes(selectedPlan) && (
                <Text className={styles.subtitle}>
                  ${PLAN_PRICING_TABLE_INFO[selectedPlan]?.price}/yr
                </Text>
              )}
              {!ANNUAL_PLAN_IDS.includes(selectedPlan) && (
                <Text className={styles.subtitle}>
                  ${PLAN_PRICING_TABLE_INFO[selectedPlan]?.price}/mth
                </Text>
              )}
            </Box>
            <Divider className={styles.divider} />
          </Box>

          {message && <p>{message}</p>}

          <div className={styles.stripeContainer}>
            <UpdatePaymentStripe
              clientSecret={clientSecret}
              onError={onError}
              onSuccess={handleNextStep}
              hideCancel
              submitText="Sign me up"
              showFreeTrialText
              planId={selectedPlan}
            />
          </div>
        </Box>
      </Box>
    </OnboardingWrapperB>
  );
}
