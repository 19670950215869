/* eslint-disable import/no-cycle */
import apiService from "api/apiService";

export const getCustomerOrders = (filter, page) => {
  let apiFilter = "";

  // change status so backend knows what to retrieve
  switch (filter) {
    case "ALL":
      apiFilter = null;
      break;
    case "UNFULFILLED":
      apiFilter = "UNFULFILLED|ORDERED";
      break;
    case "FULFILLED":
      apiFilter = "SHIPPED|FULFILLED|FULFILLED_BY_MERCHANT";
      break;
    case "SHIPPED":
      apiFilter = "SHIPPED|FULFILLED";
      break;
    default:
      apiFilter = filter;
  }

  const orderUrl = apiFilter
    ? `/orders/?status=${apiFilter}&page=${page}`
    : "/orders/";

  return apiService.get(orderUrl);
};

export const getInventoryOrders = () => apiService.get("/inventory-orders/");

export const updateOrder = (order) =>
  apiService.patch(`/orders/update/${order.id}`, order);

export const payCustomerOrder = (data) =>
  apiService.post(`/orders/${data.orderID}/place_order/`, {
    items: data.boxData,
    use_own_inventory: !!data.useOwnInventory,
  });

export const getSampleOrders = () => apiService.get("/sample-orders/");

export const orderBrandPlate = (type, cartData = null, orderData = null) =>
  apiService.post("/brandplate-orders/v2/new-order/", {
    type,
    data: cartData,
    order_data: orderData,
  });

// get the list of brand plate orders
export const getBrandPlateOrders = () => apiService.get("/brandplate-orders/");

export const getBrandPlateOrdersAssets = (orderId) =>
  apiService.get(`/brandplate-orders-assets/?order_id=${orderId}`);

// This endpoint only updates the status of the BP order under certain conditions
export const updateBrandPlateOrders = (orderId, data) =>
  apiService.put(`/brandplate-orders/${orderId}/`, data);

// This endpoint only updates the status of the BP order under certain conditions
export const approveBrandPlateMockupApi = (orderId) =>
  apiService.post(`/brandplate-orders/${orderId}/approve/`, {});

export const requestChangesToBrandPlateMockupApi = (orderId) =>
  apiService.post(`/brandplate-orders/${orderId}/request-changes/`, {});

export const sendFeedbackForBrandPlateMockupApi = (orderId, feedback) =>
  apiService.post(`/brandplate-orders/${orderId}/feedback/`, {
    feedback,
  });
