import {
  TEST_ONBOARDING_COOKIE_NAME,
  TEST_ONBOARDING_VERSION_A,
  TEST_ONBOARDING_VERSION_B,
  TEST_ONBOARDING_VERSION_B_PERCENTAGE,
} from "constants/ab_tests";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import OnboardingStepOne from "./forms/OnboardingStepOne";
import OnboardingStepOneB from "./forms/OnboardingStepOneB";

export default function SignupView() {
  const [onboardingType, setOnboardingType] = useState(null);

  useEffect(() => {
    // Check if the test cookie is already set
    const testCookie = Cookies.get(TEST_ONBOARDING_COOKIE_NAME);
    if (!testCookie) {
      // Randomly assign user to test_onboarding_a or test_onboarding_b
      const randomNumber = Math.random();

      // set signup to be 10% to onboarding_b

      const testGroup =
        randomNumber < TEST_ONBOARDING_VERSION_B_PERCENTAGE
          ? TEST_ONBOARDING_VERSION_B
          : TEST_ONBOARDING_VERSION_A;
      Cookies.set(TEST_ONBOARDING_COOKIE_NAME, testGroup, { expires: 30 }); // Setting cookie for 30 days
      setOnboardingType(testGroup);
    } else {
      setOnboardingType(testCookie);
    }
  }, []);

  return (
    <div>
      {onboardingType === TEST_ONBOARDING_VERSION_B ? (
        <OnboardingStepOneB />
      ) : (
        <OnboardingStepOne />
      )}
    </div>
  );
}
