import { configureStore } from "@reduxjs/toolkit";
import accountAddressesReducer from "./accountAddressesSlice";
import myBillingReducer from "./billingSlice";
import brandPlateReducer from "./brandPlateSlice";
import cartReducer from "./cartSlice";
import categorySlice from "./categorySlice";
import changePlanModalSlice from "./changePlanModalSlice";
import changePlanReducer from "./changePlanSlice";
import myLogoReducer from "./myLogoSlice";
import myProductSlice from "./myProductSlice";
import productModalSlice from "./productModalSlice";
import profileReducer from "./profileSlice";
import sessionReducer from "./sessionSlice";
import myShopSettingsReducer from "./shopSettignsSlice";

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    profile: profileReducer,
    cart: cartReducer,
    category: categorySlice,
    myProduct: myProductSlice,
    myLogo: myLogoReducer,
    brandPlate: brandPlateReducer,
    billing: myBillingReducer,
    changePlan: changePlanReducer,
    accountAddresses: accountAddressesReducer,
    shopSettings: myShopSettingsReducer,
    changePlanModal: changePlanModalSlice,
    productModal: productModalSlice,
  },
});
