/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
  profile: {},
  shop: {
    subscription_discount: [],
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
      state.shop = {
        ...action.payload.shop[0],
      };
    },
    setShop: (state, action) => {
      state.shop = action.payload;
      state.profile.shop[0] = action.payload;
    },
    setOnboardingStep: (state, action) => {
      state.shop.onboarding_step = action.payload;
    },
    clearProfile: (state, action) => {
      state.shop = {};
      state.profile = {};
    },
    removeSubscriptionDiscount: (state, action) => {
      state.shop.subscription_discount = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, setShop, setOnboardingStep, clearProfile , removeSubscriptionDiscount} =
  profileSlice.actions;

export const selectShop = (state) => state.profile.shop;

export default profileSlice.reducer;
