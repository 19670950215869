import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

// mui:
import { Box, CircularProgress } from "@mui/material";

// custom components:
import Button from "components/Buttons/Button";
import Dropdown from "components/Dropdown";
import Text from "components/Text";

// api services:
import { QUERY, updateShop } from "api";
import { getProfileDataPointsMetadata } from "api/endpoints/profileApi";
import { Transaction } from "api/trace";
import BlankaLogo from "assets/images/blanka-logo_trademark.png";
import QuoteCloseIcon from "components/Icons/QuoteCloseIcon";
import QuoteOpenIcon from "components/Icons/QuoteOpenIcon";
import { SMALL } from "constants/breakpoints";
import { useAnalytics } from "hooks";
import { useMediaQuery } from "react-responsive";
import OnboardingWrapperB from "../OnboardingWrapperB";
import styles from "./OnboardingQuestionsStep.module.scss";

const MAX_LENGTH = 100;

export default function OnboardingQuestionsStep() {
  const shop = useSelector((state) => state.profile.shop);
  const analytics = useAnalytics();
  const [options, setOptions] = useState(["Select"]);
  const [option, setOption] = useState("");
  const [otherMessage, setOtherMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [transaction] = useState(
    Transaction.startTransaction(
      "/onboarding-step-1",
      "onboarding",
      "Answering the question about what describes their brand "
    )
  );

  const isMobile = useMediaQuery({ maxWidth: SMALL });

  const { isLoading, data } = useQuery(
    [QUERY.getProfileDataPointsMetadata],
    () => getProfileDataPointsMetadata(),
    {
      onSuccess: (response) => {
        // Extract answer options
        const answersOptions =
          response.data?.[4]?.answers?.map((item) => item.answer_text) || [];

        // Separate the last option from the rest
        const optionsToShuffle = answersOptions.slice(0, -1);
        const lastOption = answersOptions[answersOptions.length - 1];

        // Shuffle the options except for the last one
        const shuffledOptions = optionsToShuffle.sort(
          () => Math.random() - 0.5
        );

        // Combine shuffled options with the last option
        setOptions((_) => [...shuffledOptions, lastOption]);
      },
    }
  );

  const handleSkip = async () => {
    analytics.sendEvent(analytics.ONBOARDING_QUESTION_SKPIPPED, {});
    await updateShop(shop.id, { onboarding_step: 2 });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value.length <= MAX_LENGTH) {
      setOtherMessage(value);
    }
  };

  const handleNext = async () => {
    setLoading(true);

    let answer = option;
    let otherAnswer = "";

    if (option === "Other") {
      answer = option;
      otherAnswer = otherMessage;
    }

    const answerObject = {
      id: options.findIndex((item) => item === option) - 1,
      answer,
      otherAnswer,
      has_other: option === "Other",
      question: "Which of the following best describes you?",
    };

    try {
      transaction.span.data = answerObject;
      transaction.finishTransaction();

      analytics.sendEvent(analytics.ONBOARDING_QUESTIONS_SUBMIT, answerObject);

      await updateShop(shop.id, { onboarding_step: 2, answerObject });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <OnboardingWrapperB>
      <Box className={styles.leftSide}>
        {!isMobile ? (
          <>
            <img
              className={styles.blankaLogo}
              src={BlankaLogo}
              alt="Blanka Logo"
            />
            <div className={styles.quote}>
              <QuoteOpenIcon />
              <Text variant="body1" className={styles.quoteText} fontSize="16">
                The team from Blanka has been amazing! Friendly, quick and
                accurate help to get things organised and kick start my skincare
                venture. The products are high quality and the experience
                getting my brand on them has been seemless :) Highly recommend!!
              </Text>
              <QuoteCloseIcon className={styles.closeQuote} />
              <Text variant="body1" className={styles.quoteAuthor}>
                ANTHONY
              </Text>
            </div>
          </>
        ) : (
          <img
            className={styles.blankaLogo}
            src={BlankaLogo}
            alt="Blanka Logo"
          />
        )}
      </Box>

      <Box className={styles.rightSide}>
        <Box className={styles.userUI}>
          <Text variant="h1" className={styles.title}>
            Which of the following best describes you?
          </Text>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Dropdown
                options={options}
                onChange={setOption}
                className={styles.customDropdown}
              />
              <Box className={`${styles.otherMessage}`}>
                <Text
                  variant="body2"
                  className={`${styles.txt} ${option === "Other" && styles.visible}`}
                >
                  Please specify:
                </Text>
                <input
                  type="text"
                  value={otherMessage}
                  onChange={(e) => handleInputChange(e)}
                  className={`${styles.input} ${styles.txt} ${option === "Other" && styles.visible}`}
                  multiple
                />

                <Text
                  variant="body2"
                  className={`${styles.txtCounter} ${option === "Other" && styles.visible}`}
                >
                  {otherMessage.length}/{MAX_LENGTH}
                </Text>
              </Box>
            </>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          hasChevron
          onClick={handleNext}
          disabled={
            option === "select" ||
            (option === "Other" && otherMessage === "") ||
            option === ""
          }
          loading={loading}
          size={!isMobile ? "medium" : "small"}
        >
          Next
        </Button>

        <Box className={styles.skip} onClick={handleSkip}>
          Skip
        </Box>
      </Box>
    </OnboardingWrapperB>
  );
}
