/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  planProps: {
    subtitle: "",
    isFreePlanLimit: false,
    freePlanLimitSubText: "",
    title: "",
    onSuccess: null,
  },
};

const changePlanModalSlice = createSlice({
  name: "changePlanModal",
  initialState,
  reducers: {
    toggleChangePlanModal: (state, action) => {
      if (!action.payload) {
        state.planProps = initialState.planProps;
      }
      state.isOpen = action.payload;
    },
    onSetChangeModalProps: (state, action) => {
      state.planProps = { ...state.planProps, ...action.payload };
    },
  },
});

export const { toggleChangePlanModal, onSetChangeModalProps } =
  changePlanModalSlice.actions;

export default changePlanModalSlice.reducer;
