import { QUERY } from "api";
import queryClient from "api/queryClient";
import {
  SHOP_ONBOARDING_SHOPIFY,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_FAILED,
  SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED,
  SUBSCRIPTION_STATUS_TRIALING,
  SUBSCRIPTION_STATUS_UNPAID,
} from "constants/constants";
import {
  ANNUAL_PLAN_IDS,
  GROWTH_PLAN_IDS,
  OLD_MONTHLY_PLAN_IDS,
  PAIRED_ANNUAL_AND_MONTHLY_PLANS,
  PLAN_FREE_ID,
  PLAN_LIMITS,
  PLAN_PRICING_TABLE_INFO,
  SCALE_PLAN_IDS,
  VIP_PLAN_IDS,
} from "constants/plans";
import moment from "moment";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export function useProfile() {
  const shop = useSelector((state) => state.profile.shop);
  const profile = useSelector((state) => state.profile.profile);

  // derived state:
  const vipAnnualPlan = PLAN_PRICING_TABLE_INFO[7].price;
  const vipMonthlyPlan = PLAN_PRICING_TABLE_INFO[8].price;

  const refreshProfile = async () =>
    queryClient.invalidateQueries(QUERY.getUser);
  const isFreePlan = useCallback(() => shop.plan_type === PLAN_FREE_ID, [shop]);

  const isVipMonthlyPlan = useCallback(() => vipMonthlyPlan, [shop]);

  const isVipAnnualPlan = useCallback(() => vipAnnualPlan, [shop]);

  const nextBillingDate = () =>
    moment(shop?.subscription_next_billing).format("MMMM Do");

  const isGrowthPlan = useCallback(
    () => GROWTH_PLAN_IDS.includes(shop.plan),
    [shop]
  );
  const isScalePlan = useCallback(
    () => SCALE_PLAN_IDS.includes(shop.plan),
    [shop]
  );
  const isVIPPlan = useCallback(() => VIP_PLAN_IDS.includes(shop.plan), [shop]);

  const isAnnualPlan = useCallback(
    () => ANNUAL_PLAN_IDS.includes(shop.plan),
    [shop]
  );

  // Trialling only apply if the user select a paid plan.
  const isOnTrial = useCallback(
    () => shop.subscription_status === SUBSCRIPTION_STATUS_TRIALING,
    [shop]
  );

  const isActiveOrTrial = useCallback(
    () =>
      shop.subscription_status === SUBSCRIPTION_STATUS_TRIALING ||
      shop.subscription_status === SUBSCRIPTION_STATUS_ACTIVE,
    [shop]
  );

  const isInDunning = useCallback(
    () =>
      shop.subscription_status === SUBSCRIPTION_STATUS_FAILED ||
      shop.subscription_status === SUBSCRIPTION_STATUS_UNPAID ||
      shop.subscription_status === SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED,
    [shop]
  );

  // True if the user has not used up their avaiable time on trial.
  const hasAvailableTrial = useCallback(() => shop.has_trial[shop]);

  const hasRecentBrandplatePurchase = useCallback(
    () => shop.has_brand_plate_purchase_within_30_days,
    [shop]
  );

  const hasNextPaymentDate = () => shop.subscription_next_billing;

  const hasBrandplateOrder = useCallback(
    () => shop.has_brand_plate_order,
    [shop]
  );

  const isShopifyBilling = useCallback(
    () => shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY,
    [shop]
  );

  const getPlanSavings = (id) => {
    const planInfo = PLAN_PRICING_TABLE_INFO[id];

    if (planInfo && planInfo.compare_to_price !== undefined) {
      const savings = planInfo.compare_to_price - planInfo.price;
      return savings;
    }

    return null; // Return null if the ID does not exist or compare_to_price is not available
  };

  const needsUpgrade = useCallback(() => !isFreePlan() && !isOnTrial(), [shop]);

  const isOnOldMonthlyPlan = useCallback(
    () => OLD_MONTHLY_PLAN_IDS.includes(shop.plan),
    [shop]
  );

  const getPlanLimits = () => {
    if (isFreePlan()) {
      return {
        unbranded: PLAN_LIMITS.FREE.unbranded,
        custom: PLAN_LIMITS.FREE.custom,
      };
    }
    if (isGrowthPlan()) {
      return {
        unbranded: PLAN_LIMITS.GROWTH.unbranded,
        custom: PLAN_LIMITS.GROWTH.custom,
      };
    }
    if (isScalePlan()) {
      return {
        unbranded: PLAN_LIMITS.SCALE.unbranded,
        custom: PLAN_LIMITS.SCALE.custom,
      };
    }
    if (isVIPPlan()) {
      return {
        unbranded: PLAN_LIMITS.VIP.unbranded,
        custom: PLAN_LIMITS.VIP.custom,
        vip: PLAN_LIMITS.VIP.vip,
      };
    }
    return {
      unbranded: null,
      branded: null,
      vip: null,
    };
  };

  // isOverLimit Function: This function uses the isFreePlan and isGrowthPlan to apply the appropriate logic:
  // For a Free plan, it checks if the count of unbranded products exceeds 10 and custom products exceeds 0
  // For a Growth plan, it checks if the count of branded products exceeds 10.
  const isOverLimit = useCallback(() => {
    if (
      isFreePlan() &&
      (shop.count_number_of_unbranded_products > PLAN_LIMITS.FREE.unbranded ||
        shop.count_number_of_custom_products > PLAN_LIMITS.FREE.custom)
    ) {
      return true;
    }

    /**
     * Growth plan has a limit of 10 branded products.
     * If the limit is null, it means there is no limit.
     */
    if (
      isGrowthPlan() &&
      ((shop.count_number_of_unbranded_products >
        PLAN_LIMITS.GROWTH.unbranded &&
        PLAN_LIMITS.GROWTH.unbranded !== null) ||
        shop.count_number_of_custom_products > PLAN_LIMITS.GROWTH.custom)
    ) {
      return true;
    }

    return false;
  }, [shop]);

  // check if the user is onBoarding:
  const isOnBoarding = useCallback(() => shop.onboarding_step < 5, [shop]);

  const getPlanPair = useCallback(() => {
    const planPairObject = PAIRED_ANNUAL_AND_MONTHLY_PLANS.find(
      (planPair) =>
        shop.plan in planPair || Object.values(planPair).includes(shop.plan)
    );

    if (!planPairObject) {
      return { monthly: null, annual: null };
    }

    const monthlyPlanId =
      shop.plan in planPairObject ? shop.plan : Object.keys(planPairObject)[0];
    const annualPlanId = planPairObject[monthlyPlanId];

    return { monthly: monthlyPlanId, annual: annualPlanId };
  }, [shop.plan]);

  return {
    isOnOldMonthlyPlan,
    getPlanSavings,
    nextBillingDate,
    isFreePlan,
    isAnnualPlan,
    isVIPPlan,
    isOnTrial,
    hasAvailableTrial,
    getPlanLimits,
    hasRecentBrandplatePurchase,
    needsUpgrade,
    isOverLimit,
    refreshProfile,
    isVipMonthlyPlan,
    isVipAnnualPlan,
    hasBrandplateOrder,
    isOnBoarding,
    isActiveOrTrial,
    isShopifyBilling,
    hasNextPaymentDate,
    isInDunning,
    getPlanPair,
    shop,
    profile,
  };
}

//
